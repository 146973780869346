// 検索
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import * as JsSearch from 'js-search';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { BlogCard, BlogCardRow, blogListSlice } from '../components/blog-card';
import { BigBanner } from '../components/adsense';
const url = require('url');

const Search = props => {
  // タイトル等
  const siteName = props.data.site.siteMetadata.title;
  const title = `検索 | ${siteName}`;
  const description = '検索ページです。';
  // フック
  const [blogData, setBlogData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    // urlパラメータ取得
    // 現在の url ビルド時にwindowが使用できないので
    // let nowUrl = typeof window !== `undefined` ? location.href : ''
    let nowUrl = props.location.href;
    // 検索文字設定
    let urlParams = url.parse(nowUrl, true);
    // 検索(q)getパラメータを入れる キーがないなら undefined
    let searchQuery = urlParams.query.q ? urlParams.query.q : '';
    setSearchValue(searchQuery);

    // jsSearch設定 hook内でやるとおかしくなるので
    const jsSearch = new JsSearch.Search('slug');
    // 検索キーワードをスペースで分割して複数文字列で検索
    jsSearch.tokenizer = {
      tokenize(text) {
        return text.split(/\s+/i);
      }
    };
    // 部分一致
    jsSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
    // キーワードを小文字変換
    jsSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    // 検索方法の設定
    jsSearch.searcIndex = new JsSearch.TfIdfSearchIndex();

    // 検索を行うキーを設定
    jsSearch.addIndex(['frontmatter', 'title']);
    jsSearch.addIndex(['frontmatter', 'description']);
    jsSearch.addIndex(['frontmatter', 'category']);
    // タグ用の検索キー
    jsSearch.addIndex('searchTags');

    // jsonファイルの読み込み 何回か動くのでeffect内でやる
    Axios.get('/json/search.json')
      .then(result => {
        // ドキュメント設定
        jsSearch.addDocuments(result.data);

        // 検索
        let searchResult = jsSearch.search(searchQuery);
        // 更新日の降順でソート
        searchResult = searchResult.sort((a, b) =>
          a.frontmatter.date < b.frontmatter.date ? 1 : -1
        );

        // useEffect外でセットすると再レンダリングされるので無限ループになる
        // setState直後に値を参照しても前回の値が出力される
        // 詳しくは React公式の フックに関するよくある質問 に書いてある
        setBlogData(searchResult);
      })
      .catch(error => {
        console.log(error);
      });
  }, [props]);

  return (
    <Layout title={title} description={description} pageType="search">
      <div className="w-full min-h-50px mb-5 border-solid border-b-2 border-blue-300">
        <form
          action="/search/"
          method="get"
          className="max-w-screen-xl mx-auto flex justify-between items-center"
        >
          <input
            type="text"
            name="q"
            id=""
            className="w-11/12 h-50px p-1 text-xl outline-none"
            defaultValue={searchValue}
            placeholder="Search"
          />
          <button
            type="submit"
            className="material-icons outline-none text-4xl text-blue-300"
          >
            search
          </button>
        </form>
      </div>
      <div className="mb-2 text-lg">
        検索結果:
        <span className="text-blue-300">{blogData.length}</span>件
      </div>
      {/* ブログリスト */}
      <div>
        {createBlogList(blogData)}
        {/* 4つ以下ならバナーを出す */}
        {blogData.length <= 4 && <BigBanner className="w-full" />}
      </div>
    </Layout>
  );
};

export default Search;

const createBlogList = blogPosts => {
  // ブログデータの列
  let blogRow = 2;
  // ブログデータを列数分に分割
  let blogSilceList = blogListSlice(blogPosts, blogRow);

  // ブログカードリスト
  let blogList = [];
  for (let i = 0; i < blogSilceList.length; i++) {
    // 4(2行目)の倍数個目に広告
    if (i !== 0 && i % 2 === 0) {
      blogList.push(
        <BigBanner key={blogList.length} className="w-full mb-8" />
      );
    }
    blogList.push(
      <BlogCardRow key={blogList.length} className="lg:flex lg:justify-between">
        {blogSilceList[i].map((blogData, k) => (
          <BlogCard key={k} blogData={blogData} />
        ))}
      </BlogCardRow>
    );
  }

  return blogList;
};

export const searchQuery = graphql`
  query searchQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
